import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { Col, Container, Row, Button } from "react-bootstrap";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

import styles from "../styles/success.module.scss";

const Index = () => {
  return (
    <Layout>
      <Container className={styles.wrapper}>
        <Row className="">
          <Col lg={12} className="mt-5 lg-mt-0">
            <h1 className={`${styles.title} mt-3`}>
              Message <span> Received</span>
            </h1>
            <p className="small text-center">
              We have received your message and will get back to you as soon as
              possible!
            </p>

            <div className={`${styles.social} text-center`}>
              <a
                href="https://www.facebook.com/blazensupplements/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillFacebook />
              </a>
              <a
                href="https://www.instagram.com/blazen_supplements/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram />
              </a>
            </div>
            <div className="text-center mt-3">
              <Link to="/shop">
                <Button size="lg">Continue Shopping</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Index;
